import React from 'react';

function Logo() {
  return (
    <svg
      id='tj-logo'
      viewBox='0 0 260.000000 252.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g transform='scale(0.100000, 0.100000)' stroke='none'>
        <path
          d='M1860 400 c0 88 -1 160 -3 160 -2 0 -91 -38 -198 -84 l-194 -84 -3
            590 -2 590 -23 -6 c-90 -27 -197 -11 -278 40 l-39 24 0 -621 c0 493
            -3 -620 -12 -616 -7 3 -96 42 -197 86 -102 45 -189 81 -193 81 -10 0
            -11 -304 -1 -313 3 -4 262 -7 575 -7 l568 0 0 160z m0 347 c0 18 -2 
            33 -4 33 -3 0 -76 -31 -163 -69 l-158 -69 -3 -35 -3 -36 165 71 166 
            71 0 34z m0 558 c0 322 -3 382 -19 460 -33 162 -107 268 -224 321 
            -108 49 -310 46 -444 -8 l-53 -20 0 -104 c0 -57 1 -104 3 -104 2 0 25
            7 51 16 98 34 243 -6 302 -83 51 -67 54 -100 54 -564 l0 -427 165 72
            165 71 0 -370z'
        />
      </g>
    </svg>
  );
}

export default Logo;
